import constants from './constants';
import utils from './utils';
import internalFunctions from './internalFunctions';

const {
  LOADER_VERSION,
  EVENTS,
} = constants;

const {
  collectAndRemoveAllMatches,
  scriptsDiff,
  getAttribute,
  getCacheKey,
  getCSSData,
  getHrefs,
  ensureEl,
  promiseSerial,
} = utils;

const {
  addNamespaceClass,
  createWidgetId,
  getCachedWidget,
  cleanCachedWidget,
  loadCSS,
  loadScript,
  loadWidget,
  createWidgetEvents,
} = internalFunctions;

/**
 * @class WidgetLoader
 * @description class for our WidgetLoader that will initialize widgets and facilitate communication between widget and page
 */
class WidgetLoaderClass {
  /**
   * initialize your widget with a dispatcher el to emit events and pub/sub methods
   */
  constructor(id) {
    const containerEl = document.getElementById(id);
    createWidgetEvents(containerEl, this);
  }

  /**
   * @method canRefreshWidget
   * @description To determine if we can refresh Widgets.
   * @param {string} widgetName Widget name.
   * @param {string} widgetVersion Widget version.
   * @param {boolean} enableWidgetRefresh Optional Flag to enable/disable widget refresh. Default value is true.
   * @returns {boolean} Return true if we can refresh widgets.
   */
  canRefreshWidget(widgetName, widgetVersion, enableWidgetRefresh) {
    // refresh widget if it exists, otherwise load it
    const widgets = [].slice.call(document.querySelectorAll(
      `[data-sparta-container="${widgetName}"][data-version="${widgetVersion}"]`
    ) || []);

    return enableWidgetRefresh && widgets.length > 0;
  }

  /**
   * @method load
   * @description loads a widget into the specified container
   * @param {Object} Config object, must contain keys 'name, version, container, path', optional spinner Boolean value
   * @return {Object} returns the widget loader instance object
   */
  load({ name, version, container, path, spinner, options = {}, language = 'en', enableWidgetRefresh = true, enableWidgetCache = false }) {
    const id = ensureEl(container).id;

    // remove cache if enableWidgetCache is false.
    if (!enableWidgetCache) {
      cleanCachedWidget(name, version, id);
    }

    if (!name || !version || !id) {
      console.error('invalid config object passed to widget loader');
      return false;
    }

    let containersById = [];
    const containerNodes = [];

    // check if loading widget in multiple places
    if (Array.isArray(id)) {
      window.sparta.widgetLoader[name] = { [version]: { [id]: {} } };
      containersById = id;
    } else {
      containersById.push(id);
    }

    // loop over the containers, check if they exist and load as appropriate
    containersById.forEach((currentContainer) => {
      // window.sparta.widgetLoader[name][version][currentContainer] = {loaded:false};

      // get Node for widget container
      const dest = document.getElementById(currentContainer);

      // if widget container does not exist
      if (!dest) {
        console.error('widget container destination does not exist');
        return false;
      }

      containerNodes.push(dest);
    })

    if (this.canRefreshWidget(name, version, enableWidgetRefresh)) {
      this.events.pub(EVENTS.REFRESH_WIDGET, { name, version, id: containerNodes });
    } else {
      if (!getCachedWidget(name, version, id)) {
        this.generateStyleSheet(containerNodes, spinner);
      }

      // UNCOMMENT THE CODE BELOW AND RM LINE 127 WHEN TIME TO ENABLE OVERRIDING
      // this.getWidgetOverride(path, name, version)
      //   .then((overrideVersion) => {
      //     const widgetVersion = (overrideVersion) ? overrideVersion : version;
      //     const oldVersion = (overrideVersion) ? version : null;
      //     // remove cache if enableWidgetCache is false.
      //     if (!enableWidgetCache) {
      //       cleanCachedWidget(name, widgetVersion, id);
      //     }
      //     this.callWidget(containerNodes, path, name, widgetVersion, id, options, language, oldVersion);
      //   }).catch(error => console.error(error));
      // }
      this.callWidget(containerNodes, path, name, version, id, options, language)
    }
    return this;
  }


  callWidget(containers, path, name, version, id, options, language, oldVersion = null) {
    this.widgetXHR({ containers, path, name, version, id, options, language })
      .then((xhrResp) => this.prepareLoading(xhrResp))
      .then((loadingData) => Promise.all([this.loadMultiCSS(loadingData), this.loadMultiScripts(loadingData)]))
      .then((cssAndJsData) => {
        const wasCached = (typeof cssAndJsData[0] === 'boolean') ? cssAndJsData[0] : cssAndJsData[1];
        if (!wasCached[0]) {
          const config = { name, version, id };
          loadWidget(config, oldVersion);
        }
      })
      .catch(error => console.error(error));
  }

  /**
   * @method generatedStyleSheet
   * @description add stylesheet for loading states so we don't need another HTTP request
   * @param {Array} containers array of widget container Nodes
   * @param {Boolean} [spinner=true] should we use default spinner
   * @return {undefined}
   */
  generateStyleSheet(containers, spinner = true) {
    containers.forEach((dest) => {
      // generate stylesheet for loading
      const style = document.createElement('style');

      let styles = `
        #${dest.id} > [data-sparta-container].sparta-widget-loading,
        #${dest.id} > [data-sparta-container].sparta-widget-loading > .sparta-widget {
          visibility: hidden;
          height: 100%;
          width: 100%;
        }
      `;

      // if we are supposed to show our spinner, add a style to the widget container
      if (spinner) {
        styles += `
          #${dest.id}.sparta-widget-loading {
            height: 100%;
            background-image: url('${window.sparta.widgets.spinnerLocation}');
            background-repeat: no-repeat;
            background-position: center center;
          }
        `;
      }

      // add stylesheet to page
      style.type = 'text/css';
      style.id = `${dest.id}-sparta-widget-style-sheet`;

      if (style.stylesSheet) {
        style.styleSheet.cssText = styles;
      } else {
        style.appendChild(document.createTextNode(styles));
      }

      document.head.appendChild(style);

      dest.classList.add('sparta-widget-loading');
    })
  }

  /**
   * @method loadMultiScripts
   * @description loads all the JS scripts
   * @param {Object} anonymous object of the destination, cached bool and widget scripts
   * @return {Object<Promise>} a promise of the script loading
   */
  loadMultiScripts({ dest, wasCached, widgetScripts }) {
    return new Promise((resolve, reject) => {
      if (wasCached) return resolve(true);
      widgetScripts.reduce((cur, next) => {
        return cur.then(() => loadScript(next, dest));
      }, Promise.resolve())
        .then(arrayOfResults => resolve(false)) // array of results for debugging, false for "was not cached"
        .catch(error => {
          console.error('Loading widget scripts failed.');
          reject(error);
        });
    });
  }

  /**
   * @method loadMultiCSS
   * @description loads all the JS scripts
   * @param {Object} anonymous object of the destination, cached bool, widget scripts and CSS links
   * @return {Object<Promise>} a promise of the CSS loading
   */
  loadMultiCSS({ dest, wasCached, widgetScripts, cssData }) {
    return new Promise((resolve, reject) => {
      if (wasCached) return resolve(true);
      cssData.reduce((cur, next) => {
        return cur.then(() => loadCSS(next, dest));
      }, Promise.resolve())
        .then(arrayOfResults => resolve({ dest, wasCached, widgetScripts }))
        .catch(error => {
          console.error('Loading widget scripts failed.');
          reject(error);
        });
    });
  }

  prepareLoading({ resp, data, wasCached }) {
    return new Promise((resolve, reject) => {
      const { containers, name, version, id, options, oldVersion, cssLinks } = data;
      const widgetVersion = (oldVersion) ? oldVersion : version;
      containers.forEach((currentContainer) => {
        currentContainer.innerHTML = resp;
      });

      addNamespaceClass({ name, version, id });

      // grab the last item and load the JS into that
      const dest = containers[containers.length - 1];
      dest.style.height = 'auto';
      dest.style.backgroundImage = '';

      // get all script tags so we can make the scripts load
      const widgetScripts = [].slice.call(dest.querySelectorAll('script') || []);
      const removeScriptsFromDom = scripts => scripts.forEach(script => script.parentNode.removeChild(script));
      dest.querySelector('[data-sparta-widget]').setAttribute('data-sparta-options', JSON.stringify(options));

      if (wasCached) {
        // Since Scripts are added to <head> during first run, we need not to keep it during reload.
        const widgetModules = [...dest.querySelectorAll('[data-component="module"]')]
          .map(el => window.sparta.require[name][widgetVersion][id].require(`modules/${el.dataset.moduleRef}/${el.dataset.version}/js/${el.dataset.module}`));
        const bootstrapper = window.sparta.require[name][widgetVersion][id].require('global/sparta-bootstrap-utility');
        bootstrapper.default.init(widgetModules, { name, widgetVersion, id });
      }
      const cssData = getCSSData(cssLinks);

      return resolve({ dest, wasCached, widgetScripts, cssData });
    });
  }

  /**
   * @method getWidgetOverride
   * @description check for widget version override file.
   * @param {String} path where to load the widget from
   * @param {String} name name of widget to check for override
   * @param {String} versionKey version to check for override
   * @returns {Promise} Promise of fetching the widget override JSON
  */
  getWidgetOverride(path, name, versionKey) {
    const WIDGET_OVERRIDE_FILE_PATH = `${path}/spa/widgets/${name}/${name}.override.json`;
    return new Promise(function (resolve, reject) {
      const hostName = window.location.host;
      const xhrReq = new XMLHttpRequest();
      xhrReq.open('GET', WIDGET_OVERRIDE_FILE_PATH);
      xhrReq.onreadystatechange = function readyChange() {
        if (xhrReq.readyState !== 4) return;
        //if no file exists, return null to avoid an error
        if (xhrReq.status === 404) {
          resolve(null);
        }
        if (xhrReq.status < 200 || xhrReq.status >= 300) {
          reject({
            status: xhrReq.status,
            statusText: `Unable to load widget overrides: ${WIDGET_OVERRIDE_FILE_PATH} - ${xhrReq.statusText}`
          });
        } else {
          const overrideList = JSON.parse(xhrReq.responseText);
          let overrideVersion = null;
          const override = overrideList.hasOwnProperty(versionKey) ? overrideList[versionKey].version : null;
          if (override) {
            const exclusions = overrideList[versionKey].exclusions;
            const hostnamecheck = exclusions.domains.findIndex((hostname) => hostName.includes(hostname));
            if (hostnamecheck === -1) {
              if (typeof spaParams !== 'undefined' && !exclusions.sites.includes(spaParams.siteName)) {
                overrideVersion = override;
                console.log(`${name} Widget Override with latest version ${overrideVersion}`);
              }
            }
          }
          resolve(overrideVersion);
        }
      };
      xhrReq.send();
    });
  }

  /**
   * @method widgetXHR
   * @description loads widget via AJAX request
   * @param {Array} containers array of widget container Nodes
   * @param {String} path where to load the widget from
   * @param {String} name the name of the widget
   * @param {String} version the version number of the widget to load
   * @param {String} language the language to attempt to load (en default)
   * @returns {Promise} Promise of fetching the widget code
   */
  widgetXHR({ containers, path, name, version, id, options, language, oldVersion }) {

    const xhrResponseFromCache = getCachedWidget(name, version, id);
    let data = { containers, name, version, id, options, oldVersion };
    const self = this;

    if (xhrResponseFromCache) {
      return Promise.resolve({ resp: xhrResponseFromCache, data, wasCached: true });
    }

    return new Promise(function (resolve, reject) {
      const xhrReq = new XMLHttpRequest();
      if (!options.withoutCredentials) xhrReq.withCredentials = true;

      const LANG_PATH = (!language || language.toLowerCase() === 'en') ? '' : `${language.toLowerCase()}/`;
      const WIDGET_PATH = `${path}/spa/widgets/${name}/${version}/${LANG_PATH}index.html`;

      // execute AJAX request
      xhrReq.open('GET', WIDGET_PATH);

      xhrReq.onreadystatechange = function readyChange() {
        if (xhrReq.readyState !== 4) return; // request is not complete, move along
        if (xhrReq.status < 200 || xhrReq.status >= 300) {
          containers.forEach((dest) => {
            dest.innerHTML = 'Unable to load widget';
            self.events.pub(EVENTS.ERROR_LOADING_WIDGET, { widget: { name: name, version, path: path, container: dest }, xhrReq: this });
          });
          reject({
            status: xhrReq.status,
            statusText: `Unable to load widget: ${WIDGET_PATH} - ${xhrReq.statusText}`
          });
        } else {
          const id = containers[0].id;
          const info = {
            resp: xhrReq.responseText,
            name,
            version,
            id
          };
          const cacheKey = getCacheKey(name, version, id);
          let resp = createWidgetId(info, LOADER_VERSION);
          const linkTokenStart = '<link rel=';
          const tokenEnd = '\'>';
          const cssMatches = collectAndRemoveAllMatches(resp, linkTokenStart, tokenEnd)
          resp = cssMatches.updatedStr;
          data = Object.assign({}, data, { cssLinks: cssMatches.matches });
          localStorage.setItem(cacheKey, resp);
          const res = { resp, data, wasCached: false };
          resolve(res);
        }
      }

      xhrReq.send();
    });
  }
}

export default WidgetLoaderClass
