const addLoaderScript = (path, version) => {
  if (scriptExists(path, version)) return;
  const loader = document.createElement('script');
  loader.type = 'text/javascript';
  loader.src = `${path}/spa/widgets/loader/${version}/index.js`;
  document.body.appendChild(loader);
  return loader;
};

const findVal = (object, key) => {
  let value;
  Object.keys(object).some((k) => {
      if (k === key) {
          value = object[k];
          return true;
      }
      if (object[k] && typeof object[k] === 'object') {
          value = findVal(object[k], key);
          return value !== undefined;
      }
  });
  return value;
};

const scriptsDiff = (array_a, array_b) => {
  return array_a.filter(el => array_b.every(el2 => el2.innerHTML !== el.innerHTML))
};

const scriptExists = (path, version) => {
  return (document.querySelectorAll(`script[src="${path}/spa/widgets/loader/${version}/index.js"]`).length > 0);
};

/**
  * Create script element and append it to the head
  *
  * @param {object<Element>} _script Script element
  * @param {object} options Function options
  * @returns {object<Element>} Script element
  */
 const createScript = (_script, options = {}) => {
  const script = document.createElement('script');

  const { skipBabelPolyFill } = options;

  // Copy the following properties
  const props = [
    'async',
    'charset',
    'crossorigin',
    'integrity',
    'language',
    'nonce',
    'onload',
    'referrerpolicy',
    'src',
    'type',
  ];

  props.forEach(function(prop) {
    const propVal = _script.getAttribute(prop);
    if (prop === 'src') {
      if (propVal) {
        // skip babel-polyfill loading when specified in config
        if (skipBabelPolyFill && script.src.indexOf("babel-polyfill.js") !== -1) return;

        script[prop] = propVal;
        return;
      }  else {
          script.innerHTML = _script.innerHTML;
        }
    }

    if (!propVal) return;

    script[prop] = propVal;
  });

  return script;
};

/**
  * Find text within a string starting and ending with tokens
  * @param {String} str a string to search
  * @param {String} startToken the start of a string to search
  * @param {String} endToken the end of a string to search
  * @returns {String} a string of the match if it exists
  */
const findText = (str, startToken, endToken, fullStr = true) => {
  if (!str.includes(startToken)) return;
  let substr = str.match(startToken + '(.*?)' + endToken);
  if (!substr) return false;
  substr = substr[1]
  if (!fullStr) return substr;
  return startToken + substr + endToken;
};

/**
  * Collect and remove all matches
  * @param {String} str a string to search
  * @param {String} startToken the start of a string to search
  * @param {String} endToken the end of a string to search
  * @returns {Object} an Object of matches and the updated string
  */
const collectAndRemoveAllMatches = (str) => {
  const searchRgx = new RegExp("<link[^>]*href[^>]*>", "g");
  const matches = str.match(searchRgx);
  if (!matches || !matches.length) return { matches: [], updatedStr: str };
  return { matches, updatedStr: str.replace(searchRgx, '') };
};

/**
  * Return a given attribute string
  * @param {String} str of the element searched
  * @param {String} attr of the attribute wishing to be found
  * @returns {String} a string of the value wishing to be found
  */
const getAttribute = (link, attr) => {
  return (findText(link, `${attr}="`, '"', false) || findText(link, `${attr}='`, "'", false));
}

/**
  * Create an array of hrefs from links
  * @param {Object<Array>} links Collection of links
  * @returns {Object<Array>} a collection of hrefs
  */
const getHrefs = (links) => [...links].map((link) => {
  return getAttribute(link, 'href');
});

/**
  * Get data from CSS links
  * @param {Object<Array>} links Collection of links
  * @returns {Object<Array>} a collection of Objects with hrefs and possible data includes
  */
const getCSSData = (cssLinks) => cssLinks.map((link) => ({
  href: getAttribute(link, 'href'),
  dataIncludes: getAttribute(link, 'data-includes'),
}));

/**
  * Manage promises serially
  * @param {object<Array>} promises Collection of pending promises
  * @returns {Object<Promise>} a promise that returns and array of resolutions
  */
 const promiseSerial = (promises) =>
    promises.reduce((promiseChain, currentTask) => { // sequential resolution
        return promiseChain.then(chainResults =>
          currentTask.then(currentResult => [...chainResults, currentResult])
        )
      }, Promise.resolve([]))

const removeScript = (needle, str) => {
  const startingPoint = str.indexOf(needle); // if the needle is in the string, rm the script
  const leftStr = str.slice(0, startingPoint)
  const rightStr = str.slice(startingPoint, str.length-1)
  const leftPoint = leftStr.lastIndexOf('<script')
  const rightPoint = (startingPoint + rightStr.indexOf('</script>') + '</script>'.length)
  return {
    cursor: leftPoint,
    string: str.substr(0, leftPoint) + str.substr(rightPoint)
  };
};

const insertStr = (str1, str2, pos) => str1.substring(0, pos) + str2 + str1.substring(pos);

const getCacheKey = (name, version, id) => {
  return `${name}-${version}-${id}`;
};

const isLetter = c => c.toLowerCase() != c.toUpperCase();
const generateId = () => 'id' + (new Date()).getTime();
const addElId = el => (el.id = (!el.id) ? generateId() : el.id);

const ensureEl = (elOrSelector, scope = document) => {
  if (typeof elOrSelector === 'string') {
    const str = (isLetter(elOrSelector[0])) ? `#${elOrSelector}` : elOrSelector;
    if (document.querySelectorAll(str).length !== 1) {
      throw new Error('A single element is required as a container.')
    }
    const el = document.querySelector(str);
    addElId(el);
    return el;
  }
  if (typeof elOrSelector === 'object' && elOrSelector.nodeType) {
    addElId(elOrSelector);
    return elOrSelector;
  }
  throw new Error('A valid selector or DOM Node must be passed as a container.');
};

const getComponentUrl = (str, deps) => Object
  .values(deps).find((vals) => vals.find((el) => el.includes(str)))
  .find((val) => val.includes(str));

const getComponent = (str, obj) => {
  const deps = obj.require.dependenciesMap;
  return obj.require()(getComponentUrl(str, deps)).default;
};

const widgetExists = ({ loaderVersion, name, version, id }) => {
  return (window.sparta
    && window.sparta.widgets
    && window.sparta.widgets[loaderVersion]
    && window.sparta.widgets[loaderVersion][name]
    && window.sparta.widgets[loaderVersion][name][version]
    && window.sparta.widgets[loaderVersion][name][version][id]);
};

let widget;
const setWidget = (aWidget) => {
  widget = aWidget;
  return widget;
};

const getWidget = () => widget;

export default {
  addLoaderScript,
  collectAndRemoveAllMatches,
  findVal,
  getAttribute,
  getComponent,
  getCSSData,
  getHrefs,
  getWidget,
  scriptsDiff,
  createScript,
  promiseSerial,
  getCacheKey,
  ensureEl,
  removeScript,
  insertStr,
  setWidget,
  widgetExists,
}
